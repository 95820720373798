import Highcharts, { Chart, dateFormat } from 'highcharts';
import { highchartsOptionsWithExporting, toHumanNumber } from '../../highchart_options';
import displayDataTable from '../tables/data_table';
import { chartFontFamily } from '../../constants';

// Construct an area chart.
//
// seriesA & seriesB: hashes containing the title (string) and
//   data (nested arrays) for each series
//   e.g. seriesA = {title: 'Contract values', data: @values_array]}
//
//   Highcharts expects to receive an array for each series, with nested array
//   points e.g. @values_array = [[y], [y], [y]
//
// categories: The x-axis values. These are separate from the y-axis values
//   to force Highcharts to treat it as discrete data and label it correctly.
//
// bucketSize: Size of the column widths as a string e.g. 'day', 'month',
//   'quarter'.
//
// uuid: The uuid to identify the search. Passed through when sending AJAX
//   requests for drilled down data.
//
// tableUrl: The URL to request a table of data (when clicking on a point in
//   the chart).
export default function areaChart(seriesArray, categories, bucketSize, uuid, dateline, tableUrl) {
  // if series array is empty, do not try and create the graph
  if (seriesArray == null) {
    return;
  }
  // Render Highcharts chart
  const chart = new Chart('js-chart-container', highchartsOptionsWithExporting(Highcharts, {
    chart: {
      height: 400,
      marginTop: 70,
      type: 'area'
    },
    title: { text: '' },
    xAxis: {
      type: 'datetime',
      tickLength: 0,
      crosshair: true,
      categories: parseDateSeries(categories),
      labels: {
        style: {
          fontSize: '10px',
          color: '#99A89B',
          fontFamily: chartFontFamily.join(', ')
        },
        formatter: function() {
          return xAxisFormatter.call(this);
        }
      }
    },
    yAxis: {
      title: { text: null },
      labels: {
        style: {
          fontSize: '10px',
          color: '#99A89B',
          fontFamily: chartFontFamily.join(', ')
        },
        formatter: function() {
          return '£' + toHumanNumber(this.value);
        }
      }
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        fillOpacity: 1,
        lineWidth: 0,
        marker: {
          enabled: false,
          states: {
            hover: {
              lineWidth: 0,
              radius: 0
            },
            select: {
              enabled: false
            }
          }
        },
        point: {
          events: {
            select: function() {
              onPointSelect.call(this);
            }
          }
        },
        stacking: 'normal'
      }
    },
    series: seriesArray.map(seriesConfig),
    legend: {
      itemStyle: {
        fontWeight: 'normal',
        fontSize: '12px',
        color: '#99A89B',
        fontFamily: chartFontFamily.join(', ')
      },
      align: 'right'
    },
    tooltip: {
      backgroundColor: '#FAFBFA',
      borderColor: '#E9ECE9',
      borderRadius: 4,
      borderWidth: 1,
      outside: true,
      stickOnContact: true,
      padding: 5,
      shadow: {
        color: '#005E09',
        opacity: 0.02,
        offsetX: 0,
        offsetY: 0,
        width: 8
      },
      formatter: function() {
        const date = new Date(this.x);
        const dataInfo = [];

        // First, add a date
        switch (bucketSize) {
          case 'day': {
            // A full date
            dataInfo.push(dateFormat('%e %b %Y', date));
            break;
          }
          case 'week': {
            dataInfo.push(`W${weekNumber(date)} ${date.getFullYear()}`);
            break;
          }
          case 'month': {
            // A full-ish date
            dataInfo.push(dateFormat('%b %Y', date));
            break;
          }
          case 'quarter': {
            dataInfo.push(`Q${quarter(date)} ${date.getFullYear()}`);
            break;
          }
          case 'year': {
            dataInfo.push(date.getFullYear());
            break;
          }
        }

        // Then the values from each plot
        this.points.forEach(function(point) {
          dataInfo.push(`${point.series.name}: £${toHumanNumber(point.y)}`);
        });

        // Combine them in a <dl>
        return `
          <dl class='px-1 text-grey-700 font-sans font-medium text-sm'>
            ${dataInfo.map((dataString) => `<dd>${dataString}</dd>`).join('')}
          </dl>
        `;
      },
      positioner: function(labelWidth, labelHeight, point) {
        // Offset the tooltip to be above the top of the actual graph area.
        const y = this.chart.plotTop - labelHeight - 6;
        // Center the tooltip horizontally on the point.
        const x = point.plotX + this.chart.plotLeft - labelWidth / 2;
        return { x, y };
      },
      shared: true,
      // Support rendering HTML in the tooltip
      useHTML: true
    }
  }));

  // Trigger a synthetic select event on the last point in the chart to load
  // the data table and highlight.
  if (chart.series[0].points.length > 0) {
    chart.series[0].points.slice(-1)[0].select(null, false);
  }

  function seriesConfig(series, i) {
    return {
      name: series.title,
      // Convert each value in the data array from a string to a number.
      // This is necessary because the data is passed from Rails as JSON, where all numbers
      // are converted to strings. Highcharts expects numeric values for rendering the chart correctly.
      data: series.data.map(value => parseFloat(value)),
      // Colours: green-700, green-100 & green-500 || green-500
      color: (['#005E09', '#D1F2CF', '#0E9C1B'][i] || '#0E9C1B'),
      borderWidth: 0
    };
  }

  // Internal: Handler fired when a point is selected on a series.
  function onPointSelect() {
    // Trigger AJAX send
    displayDataTable(
      tableUrl, {
        // NOTE: by_buyer: true is a flag we can send to the
        // TrendsTableController. It allows us to send the correct Mixpanel event.
        by_buyer: true,
        bucket: categories[parseInt(this.x, 10)],
        bucket_size: bucketSize,
        uuid,
        dateline
      }
    );
    // After this event has finished, set the state of corresponding series
    // points to selected.
    //
    // This is a little quirky!
    const seriesIndex = this.series.index;
    return setTimeout(selectCorrespondingSeries.bind(this, seriesIndex), 0);
  }

  // Internal: Loop through all the other series on the chart and select them.
  //
  // seriesIndex - index of the series that was selected
  function selectCorrespondingSeries(seriesIndex) {
    // Get all the series in the chart
    const series = this.series.chart.series;
    // Loop over them
    for (let i = 0; i < series.length; i++) {
      // If its not the series that was just clicked...
      if (series[i].index !== seriesIndex) {
        const point = series[i].points[this.index];
        // This is a little undocumented. Take a look at what Highcharts
        // does: https://github.com/highcharts/highcharts/blob/6c73edc2e561a950abbbe5d5d5eeeea410cfe99c/js/parts/Interaction.js#L500
        point.selected = true;
        point.setState('select');
      }
    }
  }

  // Internal: Format an x-axis label. They're similar to the tooltip labels
  // but also include the short year if its the first item in the x axis,
  // or the first month of the year.
  //
  // Example:
  //
  //   // When the value is in January
  //   xAxisFormatter()
  //   "Jan 17"
  //
  //   // When the value is half way through a year and not the first element
  //   xAxisFormatter()
  //   "Mar"
  function xAxisFormatter() {
    const date = new Date(this.value);

    // Do nothing with year labels
    if (bucketSize === 'year') { return date.getFullYear(); }

    let label;

    switch (bucketSize) {
      case 'day': {
        label = dateFormat('%e %b', date);
        break;
      }
      case 'week': {
        label = `W${weekNumber(date)}`;
        break;
      }
      case 'quarter': {
        label = `Q${quarter(date)}`;
        break;
      }
      case 'year': {
        label = date.getFullYear();
        break;
      }
      default: {
        label = dateFormat('%b', date);
        break;
      }
    }

    const month = date.getMonth();
    const dayOfMonth = date.getDate();
    const shouldShowYear = (month === 0 && (bucketSize !== 'day' || dayOfMonth === 1));

    if (
      this.isFirst ||
      shouldShowYear
    ) {
      label = `${label} '${shortYear(date)}`;
    }

    return label;
  }

  // Date parses the first argument of each point in the data array. Returns
  // number of milliseconds since epoch, which is formatted by Highcharts by
  // specifying x-axis type of 'datetime' (see above config)
  function parseDateSeries(data) {
    if (!data) { return []; }
    return data.map(function(date) {
      return Date.parse(date);
    });
  }

  function weekNumber(date) {
    const onejan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
  }

  function quarter(date) {
    const month = date.getMonth();
    return ~~(month / 3) + 1;
  }

  // Last two digits of the year
  function shortYear(date) {
    return date.getFullYear().toString().match(/\d{2}$/);
  }
}
